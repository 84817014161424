<template>
  <div dir=ltr class="wrap-big terms" v-scroll="handleScroll">
    <div class="mb-16">
      <div class="content primary--text pt-7 pb-5 mx-auto">
        <div class="h3">Terms and Conditions</div>
        <div class="sidebar">
          <div>
            <div class="link sidebar-item" v-for="(item, i) in menuList" :key="item" @click="scrollToItem(i)">
              <div class="arrow"><img src="@/assets/icon/right.svg" alt="arrow-right" /></div>
              <div class="primary--text">{{ item }}</div>
            </div>
          </div>
          <v-card :max-width="width <= 1280 ? '310' : '318'" outlined color="accent" class="pa-lg-6 pa-4 pb-lg-8 pb-6 my-5 me-5 app">
            <div class="primary--text text-h5 font-weight-black">{{ $t('apps.title') }}</div>
            <div class="primary--text mt-1">{{ $t('apps.desc') }}</div>
            <div class="mt-8 d-flex justify-space-between flex-wrap">
              <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block" style="height: 40px">
                <img
                  height="40"
                  width="120"
                  :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
                  alt="apple"
                  :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=app.snobar.client"
                target="_blank"
                class="d-block"
                style="height: 40px"
              >
                <img
                  height="40"
                  width="120"
                  :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
                  alt="google"
                  :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
                />
              </a>
            </div>
          </v-card>
        </div>
        <div class="mt-5">
          SNOBAR is licensed to You (End-User) by SNOBAR, located and registered at DAMMAM, EASTERN 2233-32422, Saudi Arabia ("Licensor"),
          for use only under the terms of this License Agreement.
        </div>
        <div class="mt-1">
          By downloading the Licensed Application from Apple's software distribution platform ("App Store") and Google's software
          distribution platform ("Play Store"), and any update thereto (as permitted by this License Agreement), You indicate that You agree
          to be bound by all of the terms and conditions of this License Agreement, and that You accept this License Agreement. App Store
          and Play Store are referred to in this License Agreement as "Services."
        </div>
        <div class="mt-1">
          The parties of this License Agreement acknowledge that the Services are not a Party to this License Agreement and are not bound by
          any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance and support
          thereof. SNOBAR, not the Services, is solely responsible for the Licensed Application and the content thereof.
        </div>
        <div class="mt-1">
          This License Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest
          <span class="blue--text text-decoration-underline" @click="openSection(5)">
            Apple Media Services Terms and Conditions and Google Play Terms of Service
          </span>
          ("Usage Rules"). SNOBAR acknowledges that it had the opportunity to review the Usage Rules and this License Agreement is not
          conflicting with them.
        </div>
        <div class="mt-1">
          SNOBAR when purchased or downloaded through the Services, is licensed to You for use only under the terms of this License
          Agreement. The Licensor reserves all rights not expressly granted to You. SNOBAR is to be used on devices that operate with
          Apple's operating systems ("i0S" and "Mac OS") or Google's operating system ("Android").
        </div>
      </div>
      <v-card id="0" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center" max-width="702" @click="openSection(0)">
        <div class="arrow">
          <img :class="section.some((i) => i == 0) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">The application</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 0)">
        <div class="mt-1">
          SNOBAR ("Licensed Application") is a piece of software created for Automotive social media, social media for car users — and
          customized for iOS and Android mobile devices ("Devices"). It is used to let car users connect with each other.
        </div>
        <div class="mt-1">
          The Licensed Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and
          Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected
          to such laws, you may not use this Licensed Application. You may not use the Licensed Application in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </div>
      </div>
      <v-card id="1" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(1)">
        <div class="arrow">
          <img :class="section.some((i) => i == 1) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Scope of License</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 1)">
        <div class="mt-1">
          2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any
          Devices that You (End-User) own or control and as permitted by the Usage Rules, with the exception that such Licensed Application
          may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.
        </div>
        <div class="mt-1">
          2.2 This license will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or
          supplement the first Licensed Application, unless a separate license is provided for such update, in which case the terms of that
          new license will govern.
        </div>
        <div class="mt-1">
          2.3 You may not share or make the Licensed Application available to third parties (unless to the degree allowed by the Usage
          Rules, and with SNOBAR's prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application.
        </div>
        <div class="mt-1">
          2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works
          or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof (except with SNOBAR's
          prior written consent).
        </div>
        <div class="mt-1">
          2.5 You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Licensed Application
          or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of
          this license, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove
          any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time.
          If you sell your Devices to a third party, you must remove the Licensed Application from the Devices before doing so.
        </div>
        <div class="mt-1">
          2.6 Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and
          damages. 2.7 Licensor reserves the right to modify the terms and conditions of licensing. 2.8 Nothing in this license should be
          interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable
          third-party terms and conditions.
        </div>
      </div>
      <v-card id="2" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(2)">
        <div class="arrow">
          <img :class="section.some((i) => i == 2) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Technical requirements</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 2)">
        <div class="mt-1">
          3.1 Licensor attempts to keep the Licensed Application updated so that it complies with modified/new versions of the firmware and
          new hardware. You are not granted rights to claim such an update.
        </div>
        <div class="mt-1">
          3.2 You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to
          use the Licensed Application satisfies the technical specifications mentioned above.
        </div>
        <div class="mt-1">3.3 Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.</div>
      </div>
      <v-card id="3" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(3)">
        <div class="arrow">
          <img :class="section.some((i) => i == 3) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Maintenance and Support</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 3)">
        <div class="mt-1">
          4.1 The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You can
          reach the Licensor at the email address listed in the App Store or Play Store Overview for this Licensed Application.
        </div>
        <div class="mt-1">
          4.2 SNOBAR and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support
          services with respect to the Licensed Application.
        </div>
      </div>
      <v-card id="4" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(4)">
        <div class="arrow">
          <img :class="section.some((i) => i == 4) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">User-generated contributions</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 4)">
        <div class="mt-1">
          The Licensed Application may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other
          functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute,
          or broadcast content and materials to us or in the Licensed Application, including but not limited to text, writings, video,
          audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions").
          Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. As
          such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any
          Contributions, you thereby represent and warrant that:
        </div>
        <ol class="mt-3">
          <li>
            The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent,
            trademark, trade secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to
            authorize us, the Licensed Application, and other users of the Licensed Application to use your Contributions in any manner
            contemplated by the Licensed Application and this License Agreement.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions
            to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your
            Contributions in any manner contemplated by the Licensed Application and this License Agreement.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam,
            mass mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise
            objectionable (as determined by us).
          </li>
          <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote
            violence against a specific person or class of people.
          </li>
          <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
          <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
          <li>
            Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health
            or well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference,
            or physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material that violates, any provision of this License Agreement, or any
            applicable law or regulation.
          </li>
        </ol>
        <div class="mt-3">
          Any use of the Licensed Application in violation of the foregoing violates this License Agreement and may result in, among other
          things, termination or suspension of your rights to use the Licensed Application.
        </div>
      </div>
      <v-card id="5" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(5)">
        <div class="arrow">
          <img :class="section.some((i) => i == 5) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Contribution license</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 5)">
        <div class="mt-1">
          By posting your Contributions to any part of the Licensed Application or making Contributions accessible to the Licensed
          Application by linking your account from the Licensed Application to any of your social networking accounts, you automatically
          grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use copy, reproduce, disclose, sell,
          resell, publish, broad cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or
          in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial
          advertising, or otherwise, and to prepare derivative works of, or incorporate in other works, such as Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.
        </div>
        <div class="mt-1">
          This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name,
          company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and
          commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not
          otherwise been asserted in your Contributions.
        </div>
        <div class="mt-1">
          We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any
          intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements
          or representations in your Contributions provided by you in any area in the Licensed Application. You are solely responsible for
          your Contributions to the Licensed Application and you expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </div>
        <div class="mt-1">
          We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to
          recategorize any Contributions to place them in more appropriate locations in the Licensed Application; and (3) to prescreen or
          delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
        </div>
      </div>
      <v-card id="6" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(6)">
        <div class="arrow">
          <img :class="section.some((i) => i == 6) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Liability</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 6)">
        <div class="mt-1">
          7.1 Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence.
          Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight
          negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned
          above does not apply to injuries to life, limb, or health.
        </div>
        <div class="mt-1">
          7.2 Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of
          this License Agreement. To avoid data loss, You are required to make use of backup functions of the Licensed Application to the
          extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations
          of the Licensed Application, You will not have access to the Licensed Application.
        </div>
      </div>
      <v-card id="7" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(7)">
        <div class="arrow">
          <img :class="section.some((i) => i == 7) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Warranty</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 7)">
        <div class="mt-1">
          8.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time
          of Your download. Licensor warrants that the Licensed Application works as described in the user documentation.
        </div>
        <div class="mt-1">
          8.2 No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorizedly
          modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with
          inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of SNOBAR's
          sphere of influence that affect the executability of the Licensed Application.
        </div>
        <div class="mt-1">
          8.3 You are required to inspect the Licensed Application immediately after installing it and notify SNOBAR about issues discovered
          without delay by email provided in Product Claims. The defect report will be taken into consideration and further investigated if
          it has been emailed within a period of ninety (90) days after discovery.
        </div>
        <div class="mt-1">
          8.4 If we confirm that the Licensed Application is defective, SNOBAR reserves a choice to remedy the situation either by means of
          solving the defect or substitute delivery.
        </div>
        <div class="mt-1">
          8.5 In the event of any failure of the Licensed Application to conform to any applicable warranty, You may notify the Services
          Store Operator, and Your Licensed Application purchase price will be refunded to You. To the maximum extent permitted by
          applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the Licensed
          Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to
          any warranty.
        </div>
        <div class="mt-1">
          8.6 If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve
          (12) months after the Licensed Application was made available to the user. The statutory periods of limitation given by law apply
          for users who are consumers.
        </div>
      </div>
      <v-card id="8" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(8)">
        <div class="arrow">
          <img :class="section.some((i) => i == 8) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Product claims</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 8)">
        <div class="mt-1">
          SNOBAR and the End-User acknowledge that SNOBAR, and not the Services, is responsible for addressing any claims of the End-User or
          any third party relating to the Licensed Application or the End-User's possession and/or use of that Licensed Application,
          including, but not limited to:
        </div>
        <ol type="i">
          <li>product liability claims;</li>
          <li>any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and</li>
          <li>
            claims arising under consumer protection, privacy, or similar legislation, including in connection with Your Licensed
            Application's use of the HealthKit and HomeKit.
          </li>
        </ol>
      </div>
      <v-card id="9" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(9)">
        <div class="arrow">
          <img :class="section.some((i) => i == 9) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Legal compliance</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 9)">
        <div class="mt-1">
          You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been
          designated by the US Government as a "terrorist supporting" country; and that You are not listed on any US Government list of
          prohibited or restricted parties.
        </div>
      </div>
      <v-card id="10" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(10)">
        <div class="arrow">
          <img :class="section.some((i) => i == 10) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Contact information</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 10)">
        <div class="mt-1">For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:</div>
        <div class="mt-1">SNOBAR</div>
        <div>DAMMAM, EASTERN 2233-32422</div>
        <div>Saudi Arabia</div>
        <div>info@snobar.app</div>
      </div>
      <v-card id="11" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(11)">
        <div class="arrow">
          <img :class="section.some((i) => i == 11) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Termination</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 11)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">
          The license is valid until terminated by SNOBAR or by You. Your rights under this license will terminate automatically and without
          notice from SNOBAR if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the
          Licensed Application, and destroy all copies, full or partial, of the Licensed Application.
        </div>
      </div>
      <v-card id="12" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(12)">
        <div class="arrow">
          <img :class="section.some((i) => i == 12) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Third-party terms of agreements and beneficiary</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 12)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">
          SNOBAR represents and warrants that SNOBAR will comply with applicable third-party terms of agreement when using Licensed
          Application
        </div>
        <div class="mt-1">
          In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," both Apple and
          Google and their subsidiaries shall be third-party beneficiaries of this End User License Agreement and — upon Your acceptance of
          the terms and conditions of this License Agreement, both Apple and Google will have the right (and will be deemed to have accepted
          the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.
        </div>
      </div>
      <v-card id="13" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(13)">
        <div class="arrow">
          <img :class="section.some((i) => i == 13) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Intellectual property rights</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 13)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">
          SNOBAR and the End-User acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User's
          possession and use of that Licensed Application infringes on the third party's intellectual property rights, SNOBAR, and not the
          Services, will be solely responsible for the investigation, defense, settlement, and discharge or any such intellectual property
          infringement claims.
        </div>
      </div>
      <v-card id="14" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(14)">
        <div class="arrow">
          <img :class="section.some((i) => i == 14) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Applicable law</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 14)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">This License Agreement is governed by the laws of Saudi Arabia excluding its conflicts of law rules.</div>
      </div>
      <v-card id="15" tile outlined class="py-3 px-md-7 px-4 mx-auto d-flex align-center mn1" max-width="702" @click="openSection(15)">
        <div class="arrow">
          <img :class="section.some((i) => i == 15) ? 'arrow-bottom' : ''" src="@/assets/icon/right.svg" alt="arrow-right" />
        </div>
        <div class="blue--text f18">Miscellaneous</div>
      </v-card>
      <div class="content primary--text pt-2 pb-5" v-if="section.some((i) => i == 15)" style="border-bottom: 1px solid #e0e0e0">
        <div class="mt-1">
          16.1 If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be
          affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.
        </div>
        <div class="mt-1">
          16.2 Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived
          in writing.
        </div>
      </div>
    </div>
    <div v-if="scrollPage >= 650" class="arrow-start link" @click="scrollToTop">
      <img src="@/assets/icon/right.svg" alt="arrow-right" />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      scrollPage: 0,
      section: [],
      menuList: [
        'The application',
        'Scope of License',
        'Technical requirements',
        'Maintenance and Support',
        'User-generated contributions',
        'Contribution license',
        'Liability',
        'Warranty',
        'Product claims',
        'Legal compliance',
        'Contact information',
        'Termination',
        'Third-party terms of agreements and beneficiary',
        'Intellectual property rights',
        'Applicable law',
        'Miscellaneous',
      ],
    };
  },
  methods: {
    scrollToItem(id) {
      this.openSection(id);
      this.$nextTick(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    },
    scrollToTop() {
      document.getElementById('start').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    openSection(s) {
      if (this.section.some((i) => i == s)) {
        const idx = this.section.indexOf(+s);
        this.section.splice(idx, 1);
      } else {
        this.section.push(+s);
      }
    },
    handleScroll() {
      this.scrollPage = window.pageYOffset;
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  computed: {
    select() {
      return this.$store.getters.langValue;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style lang="scss">
.terms {
  .sidebar {
    position: absolute;
    width: 350px;
    left: 50%;
    top: 72px;
    transform: translate(calc(-50% - 525px), 0);
    &-item {
      display: flex;
      align-items: flex-start;
      padding: 14px 8px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .app {
    border-radius: 10px !important;
    border-color: #e0e0e0 !important;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50% !important;
    background: #f1f5fa;
  }
  .arrow-bottom {
    transform: rotate(90deg);
  }
  .mn1 {
    margin-top: -1px;
  }
  .content {
    max-width: 700px;
    width: 100%;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    & > div:not(:nth-of-type(2)) {
      padding: 0 30px;
    }
  }
  @media screen and (max-width: 1399px) {
    .sidebar {
      width: 250px;
      transform: translate(calc(-50% - 475px), 0);
    }
  }
  @media screen and (max-width: 1200px) {
    .sidebar {
      width: 100%;
      position: initial;
      transform: initial;
      .app {
        display: none;
      }
    }
  }
  @media screen and (max-width: 959px) {
    .content {
      margin: 0 auto;
      & > div:not(:nth-of-type(2)) {
        padding: 0 24px;
      }
    }
    // .sidebar {
    //   display: none;
    // }
  }
  @media screen and (max-width: 599px) {
    .content {
      & > div:not(:nth-of-type(2)) {
        padding: 0 16px;
      }
    }
  }
}
// .v-application--is-rtl {
//   .terms {
//     .sidebar {
//       right: 50%;
//       left: initial;
//       transform: translate(calc(50% + 525px), 0);
//     }
//   }
// }
// @media screen and (max-width: 1399px) {
//   .v-application--is-rtl {
//     .terms {
//       .sidebar {
//         transform: translate(calc(50% + 475px), 0);
//       }
//     }
//   }
// }
// @media screen and (max-width: 1200px) {
//   .v-application--is-rtl {
//     .terms {
//       .sidebar {
//         width: 100%;
//         position: initial;
//         transform: initial;
//         .app {
//           display: none;
//         }
//       }
//     }
//   }
// }
</style>
